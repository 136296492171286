import { uiStore } from "@/stores/uiStore";
import { useStore } from "@nanostores/react";
import Link from "next/link";

export default function CookieBanner() {
  const status = useStore(uiStore.liCookieStatus);
  if (status == "unknown" || status == "valid") return null;

  // possible values now are invalid, missing, or no-extension.
  // all seem worthy of a banner.

  return (
    <div className="rounded-md border border-yellow-400 bg-yellow-100 p-4 mb-4 text-sm">
      Hey you! You don&apos;t have a valid LinkedIn cookie set, so some features won&apos;t work.{" "}
      <Link href="/setup" className="text-blue-600 hover:underline">
        Click here to return to onboarding.
      </Link>
    </div>
  );
}
