import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import API from "@/client/api";
import AutocompleteBox, { AutocompleteVariant } from "@/components/inputs/AutocompleteBox";
import { debounce, DebounceStyle } from "@/lib/debounce";
import { uiStore } from "@/stores/uiStore";
import { AutocompleteEntity, EntityType } from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { useStore } from "@nanostores/react";

export default function SearchBox({
  initialQuery,
  submitButton,
  className,
  inputClassName,
  placeholder = "Type a name or url",
  magnifyClassName,
  onSelect,
  type,
}: {
  initialQuery?: string;
  submitButton?: boolean;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  magnifyClassName?: string;
  onSelect?: (e: AutocompleteEntity) => void;
  type?: EntityType;
}) {
  const [suggestedItems, setSuggestedItems] = useState<AutocompleteEntity[]>([]);
  const focusSearchBar = useStore(uiStore.autofocusSearchBar);
  const router = useRouter();
  const DEFAULT_INPUT_CLASS_NAME =
    "bg-gray-200/50 ring-0 focus:ring-brand-300 placeholder-transparent lg:placeholder-gray-500 focus:placeholder-gray-500 focus:bg-white rounded-full pl-11";

  const navigateOnSelect = (e: AutocompleteEntity) => {
    if (location.pathname.endsWith("/wrongPerson")) {
      const url = new URL(location.href);
      url.searchParams.set("q", e.name);
      router.push(url.toString());
    } else if (e.id) {
      router.push(entityUrl(e));
    } else {
      const name = e.name.trim();
      if (new RegExp(/https?:\/\//).exec(name)) {
        // try to make an entity directly out of this url
        router.push(`/profile?url=${encodeURIComponent(e.name)}`);
      } else {
        router.push(`/search?q=${encodeURIComponent(e.name)}`);
      }
    }
  };

  const suggestItems = useCallback((query: string) => {
    if (query.length < 2) {
      setSuggestedItems([]);
      return;
    }

    debounce(
      "autocomplete",
      () => API.autocomplete({ query, type }).then(setSuggestedItems),
      500,
      DebounceStyle.QUEUE_LAST,
    );
  }, []);

  return (
    <div className={className}>
      <AutocompleteBox
        variant={AutocompleteVariant.Entities}
        initialValue={
          initialQuery ?
            { id: "", name: initialQuery, url: "", type: EntityType.Person, imageUrl: "" }
          : undefined
        }
        items={suggestedItems}
        onSelect={onSelect || navigateOnSelect}
        placeholder={placeholder}
        inputClassName={inputClassName || DEFAULT_INPUT_CLASS_NAME}
        onQueryChange={suggestItems}
        autoFocus={focusSearchBar}
        magnifyClassName={magnifyClassName}
      />
    </div>
  );
}
