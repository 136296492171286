import posthog from "posthog-js";
import { useEffect, useState } from "react";

import { FeatureFlag } from "@/types";

export function useFeatureFlag(feature: FeatureFlag) {
  const [enabled, setEnabled] = useState<boolean>(false);
  useEffect(() => {
    posthog.onFeatureFlags(function () {
      // feature flags should be available at this point
      setEnabled(!!posthog.isFeatureEnabled(feature));
    });
  }, [feature]);
  return enabled;
}
