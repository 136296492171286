import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { classNames, prettyUrl } from "@/lib/utils";
import { AutocompleteEntity } from "@/types";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

type DefaultItemType = { id: string; name: string; url?: string };

export enum AutocompleteVariant {
  Entities = "entities",
  Default = "default",
}

type GenericProps<T> = {
  items: T[];
  customIcon?: React.ReactNode;
  initialValue?: T;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
  magnifyClassName?: string;
  onSelect: (item: T) => void;
  onQueryChange?: (query: string) => void;
};

type EntityVariant = {
  variant: AutocompleteVariant.Entities;
} & GenericProps<AutocompleteEntity>;

type DefaultVariant = {
  variant: AutocompleteVariant.Default;
} & GenericProps<DefaultItemType>;

type Props = EntityVariant | DefaultVariant;

export default function AutocompleteBox({
  items,
  variant,
  className,
  inputClassName,
  initialValue,
  placeholder,
  autoFocus,
  magnifyClassName,
  onSelect,
  onQueryChange,
  customIcon,
}: Props) {
  const [query, setQuery] = useState("");
  const comboBtn = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (autoFocus && comboBtn.current) {
      comboBtn.current.click();
    }
  }, [autoFocus]);

  const wrappedOnSelect = (item: AutocompleteEntity) => {
    onSelect(item);
    setQuery("");
  };

  return (
    <Combobox
      as="div"
      onChange={wrappedOnSelect}
      defaultValue={initialValue}
      className={classNames("relative", className)}
    >
      {customIcon ?
        customIcon
      : <MagnifyingGlassIcon
          className={classNames("absolute w-5 h-5 text-gray-400 left-2 top-2", magnifyClassName)}
        />
      }

      <Combobox.Input<{ name: string }>
        className={twMerge(
          "w-full rounded-md border-0 bg-white py-1.5 pl-8 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
          inputClassName,
        )}
        onChange={(event) => {
          setQuery(event.target.value);
          onQueryChange?.(event.target.value);
        }}
        displayValue={(item) => item?.name}
        placeholder={placeholder}
        autoComplete="off"
      />

      <Combobox.Button className="hidden" ref={comboBtn} />
      <Combobox.Options
        className={classNames(
          "fixed left-0",
          "md:absolute md:top-auto",
          "mt-1 z-10 w-full max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
        )}
      >
        <Combobox.Option
          className={({ active }) =>
            classNames(
              "relative cursor-default select-none py-2 pl-3 pr-9",
              active ? "bg-indigo-600 text-white" : "text-gray-900",
            )
          }
          value={{ id: null, name: query }}
        >
          Search for {query.length ? query : "something..."}
        </Combobox.Option>
        {(() => {
          switch (variant) {
            case AutocompleteVariant.Entities: {
              return items.map((item) => <Option key={item.id} variant={variant} item={item} />);
            }
            case AutocompleteVariant.Default: {
              return items.map((item) => <Option key={item.id} variant={variant} item={item} />);
            }
            default: {
              const _exhaustiveCheck: never = variant;
              throw new Error(`Unhandled variant: ${_exhaustiveCheck}`);
            }
          }
        })()}
      </Combobox.Options>
    </Combobox>
  );
}

const Option = ({
  variant,
  item,
}:
  | { variant: AutocompleteVariant.Entities; item: AutocompleteEntity }
  | { variant: AutocompleteVariant.Default; item: DefaultItemType }) => {
  return (
    <Combobox.Option
      key={item.id}
      value={item}
      className={({ active }) =>
        classNames(
          "relative cursor-default select-none py-2 pl-3 pr-9",
          active ? "bg-indigo-600 text-white" : "text-gray-900",
        )
      }
    >
      {({ active, selected }) => (
        <span className={classNames("truncate flex gap-2 content-center items-center")}>
          {variant === AutocompleteVariant.Entities && (
            <EntityIconWithPlaceholder
              className="w-6 h-6 overflow-hidden flex-shrink-0"
              imageClassName="w-6 h-6 object-cover"
              entity={item}
            />
          )}
          {item.name}
          {item.url && ` (${prettyUrl(item.url)})`}
        </span>
      )}
    </Combobox.Option>
  );
};
