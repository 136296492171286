/* eslint-disable @next/next/no-img-element */
import { PropsWithChildren, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import uniqolor from "uniqolor";

import { makeInitials } from "@/components/ui/Avatar";
import { logger } from "@/lib/logger";
import { cachedImage, favicon } from "@/lib/utils";
import { EntityType } from "@/types";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/20/solid";

export const badUrls = [
  "https://static.licdn.com/aero-v1/sc/h/5bukxbhy9xsil5mb7c2wulfbx",
  "https://static.licdn.com/aero-v1/sc/h/akt4ae504epesldzj74dzred8",
  "https://media.licdn.com/dms/image/v2/D5635AQGXW0U7XhrOLA/profile-framedphoto-shrink_100_100/profile-framedphoto-shrink_100_100/0/1660002772504",
];

export function getEntityColors(entity: { name: string; id: string }) {
  const color1 = uniqolor(entity.name, {
    lightness: [60, 80],
  }).color;
  const color2 = uniqolor(entity.id, {
    lightness: [40, 60],
  }).color;
  return { color1, color2 };
}

export function PlaceholderBackground({
  children,
  entity,
  className,
}: PropsWithChildren<{ entity: { name: string; id: string }; className?: string }>) {
  const { color1, color2 } = getEntityColors(entity);
  return (
    <div
      className={className}
      style={{
        background: `linear-gradient(to bottom right, ${color1}, ${color2})`,
      }}
    >
      {children}
    </div>
  );
}

export function EntityIconWithPlaceholder({
  entity,
  className,
  imageClassName,
  iconClassName,
  customIcon,
  textClassName,
}: {
  entity: {
    name: string;
    id: string;
    type: string;
    imageUrl?: string | null;
    fallbackImageUrl?: string | null;
    url?: string | null;
  };
  className?: string;
  imageClassName?: string;
  iconClassName?: string;
  customIcon?: React.ReactNode;
  textClassName?: string;
}) {
  const [imgUrl, setImgUrl] = useState(entity.imageUrl);
  useEffect(() => {
    setImgUrl(entity.imageUrl);
  }, [entity.imageUrl]);

  const hasImage = !!imgUrl && !badUrls.includes(imgUrl);
  const { color1, color2 } = getEntityColors(entity);
  if (entity.imageUrl && hasImage) {
    const imageUrl = cachedImage(
      entity.url ? favicon({ favicon: imgUrl, url: entity.url }) : imgUrl,
    );

    return (
      <div className={twMerge("content-center rounded", className)}>
        <img
          src={imageUrl}
          alt={entity.name}
          className={twMerge("w-full h-full object-cover rounded", imageClassName)}
          onError={(e) => {
            logger.warn(e);
            setImgUrl(imgUrl == entity.fallbackImageUrl ? null : entity.fallbackImageUrl);
          }}
        />
      </div>
    );
  }

  const PersonEntityIcon = () => {
    return entity?.name?.[0] ?
        <div className={twMerge("flex items-center justify-center text-white", className)}>
          <p className={twMerge("text-xl", textClassName)}>{makeInitials(entity)}</p>
        </div>
      : <div className={twMerge("flex items-center justify-center text-white", className)}>
          <UserIcon className={twMerge("h-[50%] w-[50%] opacity-80", iconClassName)} />
        </div>;
  };

  return (
    <div
      className={twMerge("flex items-center justify-center text-white rounded", className)}
      style={{
        background: `linear-gradient(to bottom right, ${color1}, ${color2})`,
      }}
    >
      {customIcon ?
        customIcon
      : entity.type == EntityType.Company ?
        <BuildingOfficeIcon className={twMerge("h-[50%] w-[50%] opacity-80", iconClassName)} />
      : <PersonEntityIcon />}
    </div>
  );
}
