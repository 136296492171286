import { ButtonLink } from "@/components/ui/Button";
import { WAITLIST_URL } from "@/types";
import { usePathname } from "next/navigation";

export function LoggedOutButtons() {
  const path = usePathname();
  const redirectPath = shouldRedirectToPage(path) ? `?redirect=${path}` : "";

  return (
    <>
      <ButtonLink href={WAITLIST_URL} className="text-sm mr-4">
        Join Waitlist
      </ButtonLink>
      <ButtonLink href={`/auth/signin${redirectPath}`} className="text-sm">
        Log In
      </ButtonLink>
    </>
  );
}

const shouldRedirectToPage = (pathname: string) => {
  return !!pathname && (pathname.startsWith("/p/") || pathname.startsWith("/c/"));
};
